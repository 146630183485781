import React from 'react';
import { ReactComponent as CheckMarkSvg } from '@assets/icons/check-mark-blue.svg';
import { ReactComponent as LineSvg } from '@assets/icons/line-no-has-feature.svg';

const planFeatureContent = [
  {
    titleText: 'Usage',
    tableContent: [
      {
        features: 'Number of users',
        free: 'Includes 2 users',
        starter: 'Includes 15 users',
        team: 'Includes 20 users',
        enterprise: 'Includes 25 users'
      },
      {
        features: 'Unlimited proof links',
        free: 'Unlimited',
        starter: 'Unlimited',
        team: 'Unlimited',
        enterprise: 'Unlimited'
      },
      {
        features: 'Unlimited free reviewers',
        free: 'Unlimited',
        starter: 'Unlimited',
        team: 'Unlimited',
        enterprise: 'Unlimited'
      },
      {
        features: 'Secure storage',
        free: '2GB free storage',
        starter: '500GB free storage',
        team: '1TB free storage',
        enterprise: 'Custom storage packages'
      },
      {
        features: 'Storage add-ons',
        free: <LineSvg />,
        starter: '250GB increments',
        team: '250GB increments',
        enterprise: 'Custom'
      }
    ]
  },
  {
    titleText: 'Workspaces & Projects',
    tableContent: [
      {
        features: 'Number of workspaces',
        free: '1 workspace',
        starter: '5 workspaces',
        team: '10 workspaces',
        enterprise: 'Unlimited'
      },
      {
        features: 'Number of projects',
        free: '1 project',
        starter: '5 projects',
        team: '15 projects',
        enterprise: 'Unlimited projects'
      },
      {
        features: 'Searchable Media Library',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Creative brief per project',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Personalized activity dashboard',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Organize media in private projects',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Organize media in private folders',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Supported Media',
    tableContent: [
      {
        features: 'Review video and audio (MP4, MOV, WAV, etc)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Review images (PNG, JPEG, TIFF, etc)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Review Adobe Creative Suite (PSD, AI, etc)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features:
          'Review documents, excel and presentations (DOC, PDF, XLS, PPT)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Review animated GIFs',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Review CAD files (2D)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Review static website screenshots',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Creative Feedback & Collaboration',
    tableContent: [
      {
        features:
          'Real-time commenting on imagery, photography, presentations, PDFs and more',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Text highlighting on documents, PDFs and more',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Time-stamped comments on videos',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Range-based comments on videos',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Markup an asset (arrows, shapes, freeform, text, pins)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Drop pins on images, PDFs, presentations',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Filter and sort comments',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Unlimited versions',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Layered version management',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Version stack different file formats',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download original file',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download video proxies',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Thumbnail page viewer for PDFs and presentations',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Copy comments',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download comments (CSV, XML, plain text)',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: '@mentions to send notification to another team member',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Priority stakeholder comments',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Share for Review',
    tableContent: [
      {
        features: 'External share link for single file',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'External share link for multiple files',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Disable comments on share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Disable downloads on share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Share one or multiple versions on share link',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'View, manage and delete active share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Mobile optimized share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add custom message to share links',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Password-protected external share links',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Multi-Stage Approval Workflows',
    tableContent: [
      {
        features: 'Approval routing builder',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Approval stages',
        free: <LineSvg />,
        starter: '1 stage per approval routing',
        team: '3 stages per approval routing',
        enterprise: 'Custom'
      },
      {
        features: 'Approval Routing Reviewers',
        free: <LineSvg />,
        starter: 'Up to 5 reviewers per stage',
        team: 'Up to 10 reviewers per stage',
        enterprise: 'Unlimited'
      },
      {
        features: 'Approval status dashboard per project',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Specify when stage is completed',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set primary decision maker',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Option to skip reviewer decisions',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set upload permissions',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Automatic approval deadline reminders',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set approval routing due dates',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Upload new version while workflow in progress',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Customize approval notifications',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Pause approval workflow',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Edit in-progress approval workflow',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Restart approval workflow',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add password to approval routing invites',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add same reviewer to multiple stages',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Send approval links with due dates and media library',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add custom message to approval invites',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Approval workflow templates',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: '2 workflow templates',
        enterprise: 'Unlimited workflow templates'
      },
      {
        features: 'Lock decisions once comments submitted',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Restrict downloads until media is approved',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Batch approval routing',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },

  {
    titleText: 'Task Management',
    tableContent: [
      {
        features: 'Turn creative feedback into tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Create custom groupings for tasks',
        free: '1 custom task group per project',
        starter: '5 custom task groups per project',
        team: '10 custom task groups per project',
        enterprise: 'Unlimited'
      },
      {
        features: 'Assign project or media to task',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Assign tasks',
        free: '1 assignee per task',
        starter: '1 assignee per task',
        team: 'Multiple assignees per task',
        enterprise: 'Multiple assignees per task'
      },
      {
        features: 'Set task status',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set task due date',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set task priority',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Real-time commenting in tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Task activity log',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'View tasks per project',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Filter & sort tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Customize task notifications',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Universal task view',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Asana, Monday.com and more integrations',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Integrations',
    tableContent: [
      {
        features: 'Slack Integration',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Asana Integration',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Monday.com Integration',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'DAM/ Cloud Storage Integrations',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'API integrations (Webhooks)',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Education Gradebook',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },

  {
    titleText: 'Organization Management',
    tableContent: [
      {
        features: 'Custom organization domain',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Monitor storage usage',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Workspace invite links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Project invite links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Manage contacts',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Group management',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Project timeline log',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'White-label organization domain',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Multiple admins',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Enterprise access management',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Security & Privacy',
    tableContent: [
      {
        features: 'SOC 2 Type II ',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'AES-256 bit encryption',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Google Single Sign-On',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set user roles',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set user permissions',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set group access',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Two-factor authentication',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Single Sign-On',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Customer Success',
    tableContent: [
      {
        features: 'Knowledge Base',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'In-product onboarding',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Live chat support',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Email support',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Onboarding',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: '1 hour onboarding',
        enterprise: 'Custom'
      },
      {
        features: 'Custom implementation',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Priority support',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  }
];

export default planFeatureContent;
