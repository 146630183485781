import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Modal, Collapse } from 'antd';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTypedSelector } from '@hooks';
import { toggleBillingModal } from '@redux/actions/modalAction';
import './BillingModal.less';
import PlanCard from '@components/Modals/BillingModal/components/PlanCard';
import { useOrganization } from '@components/OrganizationBoundary';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/collapse-icon-faq.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import PlanFeatureTable from '@components/Modals/BillingModal/components/PlanFeatureTable';
import planFeatureContent from './planFeaturesList';

const faqsList = [
  {
    question: 'Is there a free version of StreamWork?',
    description: (
      <>
        <p>
          Yes! Our free plan enables you and a team member to experience
          StreamWork at no cost.
        </p>
        <p>
          If you’re looking to collaborate with more team members, collect
          feedback from clients, run approval workflows, or task manage creative
          feedback, you can upgrade to a paid plan anytime in your StreamWork
          account.
        </p>
      </>
    )
  },
  {
    question: 'Do you offer discounts for annual subscriptions?',
    description:
      'Yes, we offer discounts on annual subscriptions. Please toggle between the annual and monthly pricing at the top of this page to see the discount.'
  },
  {
    question:
      'What roles are considered a paid seat as part of a subscription plan?',
    description: (
      <>
        <p>
          A paid seat is needed for any member or contributor collaborating on a
          StreamWork project. Members are your core team, while contributors are
          external partners (ex. client, freelancer or vendor) you would like to
          grant access to specific projects only.
        </p>
        <p>
          You can share unlimited creative projects for free via share links;
          recipients don&apos;t need a paid seat.
        </p>
      </>
    )
  },
  {
    question: 'How do I upgrade my plan?',
    description: (
      <p>
        You can upgrade your plan at any time in your personal settings. Once
        logged into StreamWork, click on your photo in the top left corner, and
        select the option to “Upgrade.” If you would like to upgrade to
        Enterprise, chat with our sales team at{' '}
        <a
          href="https://www.streamwork.com/contact"
          target="_blank"
          className="is--link-price"
          rel="noreferrer"
        >
          www.streamwork.com/contact
        </a>
      </p>
    )
  },
  {
    question: 'How do I cancel my subscription?',
    description: (
      <p>
        You can cancel your monthly or annual subscription at any time by
        contacting our support team at{' '}
        <a href="mailto:support@streamwork.com">support@streamwork.com</a>.
      </p>
    )
  },
  {
    question: 'Do you offer a non-profit or educational discount?',
    description: (
      <p>
        Yes! Contact us at{' '}
        <a href="mailto:support@streamwork.com">support@streamwork.com</a>.
      </p>
    )
  },
  {
    question: 'Additional questions?',
    description: (
      <p>
        Chat with our support team or email us at{' '}
        <a href="mailto:support@streamwork.com">support@streamwork.com</a>.
      </p>
    )
  }
];

const planCards = [
  {
    type: 'free' as const,
    title: 'Free',
    description:
      'Essential creative review tools for individuals and small teams.',
    price: {
      monthly: 0,
      annually: 0
    },
    featuresTitle: 'What’s included:',
    features: [
      'Includes 2 users',
      'Unlimited proof links',
      'Unlimited free reviewers',
      '2GB free storage',
      '1 workspace',
      '1 project',
      'Commenting and markup',
      'External share links',
      'Turn feedback into tasks'
    ]
  },
  {
    type: 'starter' as const,
    title: 'Starter',
    description:
      'Powerful creative review and collaboration for growing teams.',
    price: {
      monthly: 219,
      annually: 169
    },
    featuresTitle: 'Everything in Free, plus:',
    features: [
      'Includes 15 users',
      'Unlimited proof links',
      'Unlimited free reviewers',
      '500GB free storage',
      '5 workspaces',
      '5 projects',
      '1 approval workflow stage',
      'Invite external contributors',
      'Downloadable comments',
      '5 task groupings'
    ]
  },
  {
    type: 'team' as const,
    title: 'Team',
    description:
      'Comprehensive creative review, project management and approvals for fast-moving teams.',
    price: {
      monthly: 369,
      annually: 299
    },
    featuresTitle: 'Everything in Starter, plus:',
    features: [
      'Includes 20 users',
      'Unlimited proof links',
      'Unlimited free reviewers',
      '1 TB free storage',
      '10 workspaces',
      '15 projects',
      '3 approval workflow stages',
      '2 workflow templates',
      'Private projects and folders',
      'Password-protected links',
      '@ mentions',
      '10 task groupings',
      'Integrations with Monday.com, Asana, Slack and more'
    ]
  },
  {
    type: 'enterprise' as const,
    title: 'Enterprise',
    description: 'Advanced creative workflows tailored to large organizations.',
    featuresTitle: 'Everything in Team, plus:',
    features: [
      'Includes 25 users',
      'Unlimited proof links',
      'Unlimited free reviewers',
      'Custom storage packages',
      'Unlimited workspaces',
      'Unlimited projects',
      'Custom approval workflow stages',
      'Unlimited workflow templates',
      'Priority stakeholder comments',
      'Single-sign on',
      'Unlimited task groupings',
      'Integrations with DAM / Cloud storage providers',
      'Batch approval routing',
      'Priority support'
    ]
  }
];

function BillingModal() {
  const [isMonthlyPaymentMethod, setIsMonthlyPaymentMethod] =
    useState<boolean>(true);
  const dispatch = useDispatch();
  const comparePlanRef = useRef<HTMLDivElement>(null);
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const modalState = useTypedSelector(({ modal }) => modal.billingModal);
  const { currentOrganization } = useOrganization();
  const [isOpenPlansFeatures, setIsOpenPlansFeatures] =
    useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    dispatch(toggleBillingModal({ visible: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!modalState?.visible) return;
    setIsMonthlyPaymentMethod(true);
    setIsOpenPlansFeatures(false);
  }, [modalState?.visible]);

  const textPaymentMethod = useMemo(() => {
    return isMonthlyPaymentMethod ? 'monthly' : 'annually';
  }, [isMonthlyPaymentMethod]);

  const faqsQuestionPanel = (faqsItem: any) => {
    return <div className="faqs-panel-container">{faqsItem.question}</div>;
  };

  const planType = currentOrganization.entity?.planType ?? 'free';
  const planStatus = currentOrganization.entity?.planStatus ?? 'active';
  const planPeriod = currentOrganization.entity?.planPeriod ?? 'monthly';
  const planIsCanceled =
    currentOrganization.entity?.planWillBeCanceled ?? false;

  const planButtonText = useCallback(
    (type: typeof planType) => {
      const isDowngrade = type === 'free' && planType !== 'free';
      if (isDowngrade) {
        return 'Downgrade';
      }
      const isSamePlan =
        type === 'free'
          ? type === planType
          : type === planType && textPaymentMethod === planPeriod;
      if (isSamePlan) {
        if (planStatus === 'trialing' || planIsCanceled) {
          return 'Continue with this plan';
        }
        return 'Current plan';
      }
      return type === 'enterprise' ? 'Contact us' : 'Choose';
    },
    [planType, planStatus, planPeriod, planIsCanceled, textPaymentMethod]
  );

  const planButtonDisabled = useCallback(
    (type: typeof planType) => {
      const isSamePlan =
        type === 'free'
          ? type === planType
          : type === planType && textPaymentMethod === planPeriod;
      if (isSamePlan) {
        return !(planStatus === 'trialing' || planIsCanceled);
      }
      return false;
    },
    [planType, planStatus, planPeriod, planIsCanceled, textPaymentMethod]
  );

  return (
    <Modal
      visible={!!modalState?.visible}
      destroyOnClose
      footer={null}
      zIndex={10000}
      onCancel={onCloseModal}
      className="billing-modal"
    >
      <CrossSvg className="close-task-modal" onClick={() => onCloseModal()} />
      <div className="select-plan-container" ref={parentContainerRef}>
        <div style={{ padding: '24px 24px' }}>
          <span className="title-text">Choose your plan</span>
          <div className="payment-method-container">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="react-switch">
              <input
                checked={isMonthlyPaymentMethod}
                onChange={() => {
                  setIsMonthlyPaymentMethod((prev) => !prev);
                }}
                className="react-switch-checkbox"
                type="checkbox"
              />
              <div className="react-switch-button" />
              <div className="react-switch-labels">
                <span
                  style={
                    !isMonthlyPaymentMethod
                      ? { color: '#1D1E1E' }
                      : { color: '#FFFFFF' }
                  }
                  onClick={() => {
                    if (!isMonthlyPaymentMethod)
                      setIsMonthlyPaymentMethod((prev) => !prev);
                  }}
                >
                  Annually
                </span>
                <span
                  style={
                    isMonthlyPaymentMethod
                      ? { color: '#1D1E1E' }
                      : { color: '#FFFFFF' }
                  }
                  onClick={() => {
                    if (isMonthlyPaymentMethod)
                      setIsMonthlyPaymentMethod((prev) => !prev);
                  }}
                >
                  Monthly
                </span>
              </div>
            </label>
          </div>
          <div className="plan-list-container">
            {planCards.map((plan) => (
              <PlanCard
                key={plan.type}
                name={plan.title}
                value={plan.type}
                paymentMethod={textPaymentMethod}
                description={plan.description}
                price={
                  isMonthlyPaymentMethod
                    ? plan.price?.monthly
                    : plan.price?.annually
                }
                buttonText={planButtonText(plan.type)}
                isButtonDisabled={planButtonDisabled(plan.type)}
                offerTitle={plan.featuresTitle}
                offerServicesList={plan.features}
              />
            ))}
          </div>
        </div>
        <div className="compare-plans-container" ref={comparePlanRef}>
          <div
            className="compare-plans-content"
            style={{ border: '2px dashed #fff' }}
          >
            <div className="compare-plans-title">
              <span>Compare</span> plans & feature
            </div>
            <div
              className={classNames(
                'compare-plans-button',
                isOpenPlansFeatures && 'active-compare-plans-button'
              )}
              onClick={() => setIsOpenPlansFeatures(!isOpenPlansFeatures)}
            >
              <span>
                {isOpenPlansFeatures
                  ? 'Hide plan features'
                  : 'View all plan features'}
              </span>
              <ArrowDownSvg />
            </div>
          </div>
          {isOpenPlansFeatures && (
            <div>
              <div className="feature-plan-table-title">
                <span>Features</span>
                <span>Free</span>
                <span>Starter</span>
                <span>Team</span>
                <span>Enterprise</span>
              </div>
              {planFeatureContent.map((item, i) => (
                <div className="feature-plan-container" key={i}>
                  <span>{item.titleText}</span>
                  <PlanFeatureTable tableContent={item.tableContent} />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="fqs-container">
          <span>FAQs</span>
          <Collapse
            className="faqs-collapse-container"
            expandIconPosition="end"
            expandIcon={PlusSvg}
          >
            {faqsList.map((item, number) => (
              <Collapse.Panel
                key={number.toString()}
                header={faqsQuestionPanel(item)}
              >
                <div className="faqs-description-container">
                  {item.description}
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </Modal>
  );
}

export default BillingModal;
